import React from "react";
import { Switch, Redirect } from "react-router-dom";
import CustomRoute from "./CustomRoute";

import Entrar from "~/pages/Entrar";
import Sistema from "~/pages/Sistema";
import RelatorioDashboard from "~/pages/RelatorioDashboard";
import PesquisaSatisfacao from "~/pages/PesquisaSatisfacao";

export default function Routes() {
  return (
    <Switch>
      {/* <CustomRoute exact path="/">
        <Redirect to="/" />
      </CustomRoute> */}

      {/* Rotas globais */}
      <CustomRoute path="/" exact component={Entrar} />

      {/* Rotas Plataforma */}
      <CustomRoute path="/plataforma" exact component={Sistema} privado />

      {/* Rotas Plataforma */}
      <CustomRoute path="/cliente/relatorioDashboard/:hash" exact component={RelatorioDashboard} privado={false} />

      {/* Rotas Plataforma */}
      <CustomRoute path="/cliente/pesquisasatisfacao/:hash" exact component={PesquisaSatisfacao} privado={false} />
    </Switch>
  );
}
