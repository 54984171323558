const tema = localStorage.getItem("tema");

export const Colors = tema == "E" ? {
  Color1: '#15141A',
  Color2: '#23202B',
  Color3: '#CFD1D7',
  Color4: '#9F9F9F',
  Color5: '#626262',
  Color6: '#67B0CF',
  Color7: '#CFD1D7',
  Color8: '#1C1922',
  Color9: '#484255',
  Color10: '#dfdfdf',
  Color11: '#2F2B39',
  Color12: '#BCBCBC',
  Color13: '#2ED3B5',
  Color14: '#24212C',
  Color16: '#484255',
  Color18: '#FFAB48',
  Color19: '#B6DF53', // Verde Escuro
  Color22: '#484255',
  Color23: '#2ED3B5',
  Color24: '#1C1922',
  Color25: '#2D2937',
  Color26: '#2F2A39',
  Color27: '#2F2A39',
  Color28: '#BCBCBC',
  Color29: '#23202B',
  Color30: '#15141A',
  Color31: '#1D1C22',
  Color32: '#17161A',
  Color33: '#9F9F9F',
  Color34: '#FFC6C6', // Vermelho
  Color35: '#A44747', // Vermelho Escuro
  Color36: '#A6A6A6',
  Color37: '#15141A',
  Color38: '#27252D',
  Color39: '#15141A',
  Color40: '#F0F4F9',
  Color41: '#868789',
  Color42: '#C4C4C4',
  Color43: '#2ED3B5',

  // Color6: '#9A9A9A',
  // Color9: '#F7F8FA',
  // Color10: '#A6B2C8',
  // Color11: '#F1F3F8',
  // Color12: '#434343',
  // Color13: '#2A292A',
  // Color14: '#8F9DAF',
  // Color18: '#B1E5A8', // Verde
  // Color20: '#50A342', // Verde Escuro
  // Color20: '#9BABBE',
  // Color21: '#2C2C2C',

  // Color26: '#242424',
  // Color27: '#1B1B1B',
} : {
  Color1: '#F9F9F9',
  Color2: '#FFFFFF',
  Color3: '#595D6C',
  Color4: '#9F9F9F',
  Color5: '#626262',
  Color6: '#67B0CF',
  Color7: '#969AAA',
  Color8: '#F8F8F8',
  Color9: '#e6e6e6',
  Color10: '#dfdfdf',
  Color11: '#F3F3F3',
  Color12: '#6D707D',
  Color13: '#2ED3B5',
  Color14: '#EFF1F4',
  Color15: '#969AAA',
  Color16: '#F6F8FB',
  Color17: '#75859E',
  Color18: '#FFAB48',
  Color19: '#B6DF53',
  Color20: '#F1F3F5',
  Color21: '#2ED3B5',
  Color23: '#2ED3B5',
  Color24: '#F9F9F9',
  Color25: '#F9F9F9',
  Color26: '#F9F9F9',
  Color27: '#2F2A39',
  Color28: '#969AAA',
  Color29: '#F1F1F1',
  Color30: '#2ED3B5',
  Color31: '#F8F8F8',
  Color32: '#E6E6E6',
  Color33: '#9F9F9F',
  Color34: '#F9F0F3', // Vermelho
  Color35: '#FF5780', // Vermelho Escuro
  Color36: '#9F9F9F',
  Color37: '#F3F3F3',
  Color38: '#F6F7F9',
  Color39: '#FFFFFF',
  Color40: '#F0F4F9',
  Color41: '#868789',
  Color42: '#C4C4C4',
  Color43: '#2ED3B5',
  
  // Color5: '#575858',
  // Color6: '#9A9A9A',
  // Color7: '#B1B1B1',
  // Color8: '#DAEDF6',
  // Color9: '#F7F8FA',
  // Color10: '#A6B2C8',
  // Color11: '#F1F3F8',
  // Color12: '#e5e5e5',
  // Color13: '#FFFFFF', //Era #2A292A
  // Color14: '#8F9DAF',
  // Color15: '#FFC6C6', // Vermelho
  // Color16: '#C62828', // Vermelho Escuro
  // Color17: '#A44747', // Vermelho Escuro
  // Color18: '#B1E5A8', // Verde
  // Color19: '#298D18', // Verde Escuro
  // Color20: '#50A342', // Verde Escuro
  // Color20: '#9BABBE',
  // Color21: '#E4EEF6',
  // Color22: '#E4EEF6',
  // Color23: '#E4EEF6',
  // Color24: '#FFFFFF',
  // color25: '#868585',
  // Color26: '#FFFFFF',
};